import { createSlice } from '@reduxjs/toolkit'

export const propertySlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0
  },
  reducers: {
    create: (state, action) => {
      console.log("inmueble creado", action.payload)
      return state
    },
    createError: (state, action) => {
      console.log("error al crear el inmueble", action.err)
      return state
    },
  }
})

export const { create, createError } = propertySlice.actions

export default propertySlice.reducer