import React, { Component } from 'react'
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { signIn } from '../../store/actions/authActions';

class SignIn extends Component {
    state = {
        email:"",
        password: "",
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    }

    render() {
        const { authError, auth } = this.props

        if(auth.uid) return <Redirect to="/" />
        return (
            <div className="container d-flex justify-content-center my-5">
                <div className="card my-5 col-5">
                    <h1 className="h2 mt-5 text-center card-title">Sign In</h1>
                    <form onSubmit={this.handleSubmit} className="my-1">
                        <div className="form-group mb-2">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" className="form-control" onChange={this.handleChange} />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" className="form-control" onChange={this.handleChange} />
                        </div>
                        <div className="form-group mb-0 text-center">
                            <button className="btn btn-primary py-1 mb-1">Login</button>
                            <div className="red-text center">
                                { authError ? <p>{authError}</p>:null }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials)=> dispatch(signIn(credentials))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
