import React from "react";
import PropertySummary from "./PropertySummary";


const PropertyList = ({propiedades}) => {
    return (
        <div className="row mt-5">
            { propiedades && propiedades.map(propiedad => {
                return (
                    <PropertySummary propiedad={propiedad} key={propiedad.id}/>
                )
            })}
        </div>
    )
}

export default PropertyList