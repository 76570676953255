import { configureStore } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import { getFirestore, reduxFirestore } from "redux-firestore";
import fbConfig from "../config/fbConfig";
import firebase from "firebase/app";
import reducer from "./reducers/rootReducer";


const preloadedState = {

}

export default configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false,
            /*{
                // Ignore these action types
                //ignoredActions: ['your/action/type'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ["auth", 'payload.data.*.fechaAlta'],
                // Ignore these paths in the state
                ignoredPaths: ['propiedades.fechaAlta'],
            }*/
            thunk: {
                extraArgument: { getFirebase, getFirestore }
            }
        }),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
    enhancers: [reduxFirestore(firebase, fbConfig)],
})