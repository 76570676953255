import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions"

const SignedInLinks = (props) => {
    return(
        <ul className="navbar-nav">
            <li className="nav-item"><NavLink className="nav-link" to="/create">Crear Propiedad</NavLink></li>
            <li className="nav-item"><a className="nav-link" href="/" onClick={props.signOut}>Log Out</a></li>
            <li className="nav-item"><NavLink to="/" className="btn btn-primary">{props.profile.initials}</NavLink></li>
        </ul>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);