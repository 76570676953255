import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { signUp } from "../../store/actions/authActions"

class SignUp extends Component {
    state = {
        email:"",
        password: "",
        firstName: "",
        lastName:""
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signUp(this.state)
    }

    render() {
        const { auth, authError } = this.props;

        if(auth.uid) return <Redirect to="/" />
        return (
            <div className="container d-flex justify-content-center my-5">
                <div className="row my-5">
                    <div className="card my-5">
                        <form onSubmit={this.handleSubmit} className="my-1 white">
                            <h5 className="grey-text text-darken-3">Sign Up</h5>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input className="form-control" type="email" id="email" onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input className="form-control" type="password" id="password" onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input className="form-control" type="text" id="firstName" onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input className="form-control" type="text" id="lastName" onChange={this.handleChange} />
                            </div>
                            <div className="form-group mb-0 text-center">
                                <button className="btn btn-primary py-1 mb-1">Login</button>
                            </div>
                            <div className="red-text center">
                                { authError ? <p>{authError}</p> : null }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth, 
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
