import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/auth"

// Your web app's Firebase configuration
var config = {
    apiKey: "AIzaSyDhk1lKVYCKY6RVgHJhtx53Kxkfo0ri2O0",
    authDomain: "giraa-34943.firebaseapp.com",
    projectId: "giraa-34943",
    storageBucket: "giraa-34943.appspot.com",
    messagingSenderId: "997447027869",
    appId: "1:997447027869:web:93febbeff872f0ab625874",
    measurementId: "G-4M1TN2XERZ"
};

// Initialize Firebase
firebase.initializeApp(config);
firebase.firestore(); 

export default firebase;