import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

const PropertySummary = ({propiedad}) => {
    return(
        <div className="col-sm-12 col-md-6 col-lg-4 my-4">
                <div className="card shadow-sm">
                    <Link to={"/propiedad/" + propiedad.id}>
                        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" 
                            xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" 
                            preserveAspectRatio="xMidYMid slice" focusable="false">
                                <rect width="100%" height="100%" fill="#55595c"/>
                                <text x="30%" y="50%" fill="#eceeef" dy=".3em">Placeholder Image</text>
                        </svg>
                    </Link> 
                    <div className="card-body">
                        <h2 className="card-title"><NumberFormat value={propiedad.valVenta } displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0}/></h2>
                        <p className="card-text">{propiedad.nombre}</p>
                        <div className="d-flex justify-content-around align-items-center">
                            <i className="fas fa-bed"></i><span>{propiedad.cuartos}</span>
                            <i className="fas fa-shower"></i><span>{propiedad.baños}</span>
                            <i className="fas fa-car"></i><span>{propiedad.estacionamiento}</span>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default PropertySummary;