import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import PropertyDetails from "./components/propiedades/PropertyDetails";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SingUp";
import CreateProperty from "./components/propiedades/CreateProperty";
import Footer from "./components/layout/Footer";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/propiedad/:id" component={PropertyDetails} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/create" component={CreateProperty} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
