export const createProperty = (propiedad) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to db
        const firestore = getFirestore();
        
        firestore.collection("propiedades").add({
            ...propiedad,
            fechaAlta: new Date()
        }).then(()=>{
            dispatch({type: "create", propiedad: propiedad });
        }).catch((err)=>{
            dispatch({type: "createError", err})
        })
    }
};

