import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from "react-redux"
import { useFirestoreConnect } from 'react-redux-firebase';


const PropertyDetails = (props) => {

    const id = props.match.params.id;
    
    useFirestoreConnect(() => [
        { collection: 'propiedades', doc: id }
      ])

    const propiedad = useSelector( ({ firestore: { data }}) => data.propiedades && data.propiedades[id])

    const handleClick = (e) => {
        
    }
    
    if(propiedad){
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="card my-5">
                        <h1 className="h2 card-title mt-3 text-center">{propiedad.nombre}</h1>
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <svg className="bd-placeholder-img card-img-top" width="100%" height="540" 
                                    xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" 
                                    preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <rect width="100%" height="100%" fill="#55595c"/>
                                        <text x="30%" y="50%" fill="#eceeef" dy=".3em">Placeholder Image</text>
                                </svg>
                            </div>
                            <div className="card-content p-5 col-md-4">
                                <p className="card-subtitle text-muted">Casa en Venta</p>
                                <h3 className="card-title my-2"><NumberFormat value={propiedad.valVenta } displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0}/></h3>
                                <div className="container-fluid">
                                    <ul className="my-3"style={{listStyle: "none", paddingInlineStart: "0"}}>
                                        <li className="py-1"><i className="fas fa-bed"></i><span className="px-2">{propiedad.cuartos}</span></li>
                                        <li className="py-1"><i className="fas fa-car"></i><span className="px-2">{propiedad.estacionamiento}</span></li>
                                        <li className="py-1"><i className="fas fa-shower"></i><span className="px-2">{propiedad.baños}</span></li>
                                        <li className="py-1"><i className="fas fa-expand"></i><span className="px-2">{propiedad.m2Terreno} m2</span></li>
                                        <li className="py-1"><i className="fas fa-home"></i><span className="px-2">{propiedad.m2Construccion} m2</span></li>
                                    </ul>
                                </div>
                                <div className="container-fluid text-center">
                                    <button className="btn btn-primary" onClick={handleClick}>Agenda tu cita</button>
                                </div>
                            </div>
                        </div>
                        <div className="card text-center">
                            <div className="x">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="ubicación-tab" data-bs-toggle="tab" data-bs-target="#contenido-ubicación" 
                                        type="button" role="tab" aria-controls="ubicación" aria-selected="true">Ubicación</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="descripción-tab" data-bs-toggle="tab" data-bs-target="#contenido-descripción" 
                                        type="button" role="tab" aria-controls="descripción" aria-selected="false">Descripción</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="contacto-tab" data-bs-toggle="tab" data-bs-target="#contenido-contacto" 
                                        type="button" role="tab" aria-controls="contacto" aria-selected="false">Contacto</button>
                                    </li>
                                </ul>
                            </div>
                     
                            <div className="card-body">
                                <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="contenido-ubicación" role="tabpanel" aria-labelledby="ubicación-tab">
                                    <p>Incluir Mapa</p>
                                </div>
                                <div className="tab-pane fade" id="contenido-descripción" role="tabpanel" aria-labelledby="descripción-tab">
                                    <p>Incluir detalles</p>
                                </div>
                                <div className="tab-pane fade" id="contenido-contacto" role="tabpanel" aria-labelledby="contacto-tab">
                                    <p>incluir contacto</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container">
                <div className="card my-5">
                    <h1 className="h2 card-title mt-3 text-center">Cargando propiedad...</h1>
                </div>
            </div>
        )
    }
}

export default PropertyDetails
