import authReducer from "./authReducer";
import propertyReducer from "../slice/propertySlice";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const reducer = {
   auth: authReducer,
   propiedad: propertyReducer, 
   firebase: firebaseReducer,
   firestore: firestoreReducer
};

export default reducer;

