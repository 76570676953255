import React from "react";

const Footer = () => {

    return(
        <footer className="text-center text-lg-start bg-light text-muted">
            <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
            </section>

            <section className="">
                <div className="container-fluid text-center text-md-start mt-5">
                    <div className="row mt-4">
                        <div className="col-md-3 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">GIRAA</h6>
                            <p> </p>
                        </div>

                        <div className="col-md-4 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Redes Sociales</h6>
                            <a href="/" className="me-4 text-reset"><i className="fab fa-facebook-f"></i></a>
                            <a href="/" className="me-4 text-reset"><i className="fab fa-youtube"></i></a>
                        </div>

                        <div className="col-md-4 mx-auto mb-md-0 mb-4">
                            <div className="row">
                                <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
                                <div className="col-md-6">
                                    <p><i className="fas fa-home me-3"></i> Saltillo, Coahuila</p>
                                    <a href="/" className="me-4 text-reset"><i className="fas fa-envelope me-3"></i>contacto@giraa.com.mx</a>
                                </div>
                                <div className="col-md-6">
                                    <p><i className="fas fa-phone me-3"></i> +52 844 667 3413</p>
                                    <p><i className="fab fa-whatsapp me-3"></i> +52 844 ### ####</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                <small>Los precios en esta página son representativos y pueden cambiar sin previo aviso. Agenda una cita para ofrecerte el úlitmo precio disponible.</small>
            </div>
        </footer>
    )
};

export default Footer;