import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { createProperty } from '../../store/actions/propertyActions'

class CreateProperty extends Component {
    state = {
  
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createProperty(this.state);
        this.props.history.push("/");
    }
    
    render() {

        const { auth } = this.props; 

        if(!auth.uid) return <Redirect to="/signin" />

        return (
            <div className="container card my-5">
                <h1 className="h2 card-title mt-3 text-center">Dar de alta nueva propiedad</h1>
                <form onSubmit={this.handleSubmit} className="m-1">
                    <div className="row my-3">
                        <p className="card-subtitle mb-2 text-muted">Datos Generales</p>
                         {/* Nombre */}
                        <div className="form-group col-md-12">
                            <label for="nombre" className="mb-1">Nombre</label>
                            <input type="text" className="form-control" id="nombre" placeholder="Nombre" onChange={this.handleChange}/>
                        </div>
                        {/* Dirección */}
                        <div className="form-group col-md-12">
                            <label for="dirección" className="mb-1">Dirección</label>
                            <input type="text" className="form-control" id="dirección" placeholder="Dirección" onChange={this.handleChange}/>
                        </div>
                        {/* fraccionamiento */}
                        <div className="form-group col-md-6">
                            <label for="fracc" className="mb-1">Fraccionamiento</label>
                            <input type="text" className="form-control" id="fracc" placeholder="Fraccionamiento" onChange={this.handleChange}/>
                        </div>
                        {/* Modelo */}
                        <div className="form-group col-md-6">
                            <label for="modelo" className="mb-1">Modelo</label>
                            <input type="text" className="form-control" id="modelo" placeholder="Modelo" onChange={this.handleChange}/>
                        </div>

                        {/* Renta */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="valRenta" className="mb-1">Precio de Renta</label>
                            <input type="number" min="0" id="valRenta" className="form-control" onChange={this.handleChange} />
                        </div>
                        
                        {/* Venta */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="valVenta" className="mb-1">Precio de Venta</label>
                            <input type="number" min="0" id="valVenta" className="form-control" onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="row my-3">
                        <p className="card-subtitle mt-2 text-muted">Caracteristicas Casa</p>                        
                        {/* Cuartos */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="cuartos" className="mb-1">Numero de cuartos</label>
                            <input type="number" min="0" id="cuartos" className="form-control" onChange={this.handleChange} />
                        </div>
                        
                        {/* Baños */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="baños" className="mb-1">Numero de baños</label>
                            <input type="number" min="0" id="baños" step="0.5" className="form-control" onChange={this.handleChange} />
                        </div>
                        
                        {/* Estacionamientos */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="estacionamiento" className="mb-1">Estacionamientos</label>
                            <input type="number" min="0" id="estacionamiento" className="form-control" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="row my-3">
                        <p className="card-subtitle mt-2 text-muted">Caracteristicas del Terreno</p> 
                        {/* Tipo de Terreno */}
                        {/* uploads type? */}
                        <div className="form-group my-3 col-md-2">
                            <label for="tipoTerreno" className="mb-1">Tipo de Terreno</label>
                            <select id="tipoTerreno" className="form-control" onChange={this.handleChange} defaultValue="Regular">
                                <option>Regular</option>
                                <option>Rectangular</option>
                                <option>Irregular</option>
                            </select>
                        </div>
      
                        {/* M2 de Terreno */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="m2Terreno" className="mb-1">M2 de Terreno</label>
                            <input type="text" id="m2Terreno" className="form-control" onChange={this.handleChange} />
                        </div>
                        
                        {/* M2 de Construcción */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="m2Construccion" className="mb-1">M2 de Construcción</label>
                            <input type="text" id="m2Construccion" className="form-control" onChange={this.handleChange} />
                        </div>
                        
                        {/* ML de Construcción */}
                        <div className="form-group my-3 col-md-2">
                            <label htmlFor="mlFrente" className="mb-1">ML de Frente</label>
                            <input type="text" id="mlFrente" className="form-control" onChange={this.handleChange} />
                        </div>
                    
                    </div> 

                    {/* Submit Button */}
                    <div className="form-group my-1 text-center">
                        <button className="btn btn-primary" type="submit">Crear</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createProperty: (propiedad) => dispatch(createProperty(propiedad))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProperty)
