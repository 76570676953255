import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import PropertyList from "../propiedades/PropertyList";

const Dashboard = () => {

    useFirestoreConnect([
        { 
            collection: 'propiedades',
            orderBy: ['fechaAlta', 'desc']
        }
    ])
    const propiedades = useSelector((state) => state.firestore.ordered.propiedades)
    
    return (
        <div className="properties container ">
            <div className="row">
                <div className="col s12 ">
                    <PropertyList propiedades={propiedades}/>
                </div>
            </div>
        </div>
    )
}

export default Dashboard